.property {
  padding-top: 6rem;
}
.property h2 {
  font-size: 2rem !important;
  border-left: 7px solid #0f2480;
  width: 27%;
  margin: 0px auto;
  text-align: center;
  padding-left: 5px;
  margin-bottom: 35px;
}

.lidiv {
  margin: 0px auto;
  text-align: center;
}
ul#li li {
  display: inline;
}

.Buying.selected {
  border-bottom: 3px solid #0f2481 !important;
  color: #0f2481;
}

label.buygg {
  margin: 0;
  padding: 8px 0;
  cursor: pointer;
}

.lidiv label {
  width: 12%;
  padding-bottom: 10px;
  text-align: center;
  display: inline-block;
  border-bottom: 3px solid #fff;
}
.liradio {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: unset;
}
.lifrom {
  width: 100%;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .property h2 {
    width: 59%;
    font-size: 24px !important;
  }
}
