@media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
  video.banner-video {
    height: unset !important;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  video.banner-video {
    height: unset !important;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 575px) {
  video.banner-video {
    height: unset !important;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .banner-container video {
    height: unset !important;
  }
}
