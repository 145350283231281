  
.flx_svg {
    display: flex;
    margin: 0 0 23px 0;
  }
.hmest {
    margin-right: 10px;
    width: 53px;
    font-size: 14px;
}
.hello7 p {
    font-weight: 600;
    color:#000;
}
.hello7 span {
    font-weight: 400;
    float: right;
    font-size: 16px;
}
.hmest img {
    width: 47%;
    vertical-align: sub;
    /* border: solid 5px red */
  }
  .hello h5 {
    margin-bottom: 0;
    font-size: 21px !important;
}
.container-x {
    max-width: 1200px;
    margin: 0 auto;
}
.hello7 h6 {
    font-size: 21px !important;
}
.hello {
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0px;
}
  .amount{
    font-size: 1rem;
  }
  .dimension{
    font-size: 1rem;
  }
  .tke_out span {
    margin-left: 6px;
  }
  .tke_out {
    padding: 16px;
    padding-top: 9px;
  }
  .main_1{
    margin-top:36px,
  }
  .body{
    font-size: 1rem;
  }
  /* Container for the main image and carousel */
.main-image-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 380px;
    overflow: hidden;
}

/* Styling for the image inside the main image container */
.main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.desc {
    border-right: 1px solid #d8d8d8;
}
/* Button styles for navigating images */
.nav-button {
    position: absolute;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }
    z-index: 10;
}

.prev-button {
    left: 10px;
    transform: translateY(-50%);
}

.next-button {
    right: 10px;
    transform: translateY(-50%);
}

.fullscreen-button {
    bottom: 10px;
    right: 10px;
}

/* Container for thumbnails and additional images */
.thumbnail-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.thumbnail {
    position: relative;
    cursor: pointer;
}

.thumbnail img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
}

/* Summary indicator for additional images */
.summary-indicator {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    border: 2px solid #fff;
    font-weight: bold;
}

.summary-indicator img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3;
}

/* Full-screen carousel overlay */
.carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1300;
}
.hmest span {
    vertical-align: top;
    margin-left: 9px;
}
span.resale {
    background: #007dff;
    color: #fff;
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 100px;
}
p.amount span {
    float: right;
    font-size: 16px;
}
p.dimension span {
    float: right;
}
.amount {
    margin: 0;
    font-size: 1rem;
}
.hello {
    margin: 0 !important;
}
.hello7 {
    margin: 0 !important;
}
/* Close button for carousel */
.carousel-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }
    z-index: 1301;
}
/* Contact modal css */
.MuiPaper-root {
    width: 540px;
    /* height: 300px; */
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-y: unset;
}

  @media screen and (min-device-width: 320px) and (max-device-width: 575px) {
    
  }