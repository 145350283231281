/* Newhome.css or your main stylesheet */

.buy:checked + label.buyggg {
  background: #0f2481;
  color: #fff;
}

label.buyggg {
  display: block;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.responsive-image {
  width: 100%;
  height: 100%;
}

.vdo_bnr_mg {
  position: absolute;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  height: 296px;
  object-fit: cover;
}

#banner {
  position: relative;
  max-width: 100%;
  margin: 0px !important;
  padding: 0px !important;
  color: #0b0b0b;
}

.overlayy {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0 0 0 / 19%);
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.detail-box {
  padding-bottom: 0;
  padding-top: 4rem;
}

.detail-box h1 {
  padding-left: 5px;
  border-left: 7px solid #0f2480;
  font-size: 2rem !important;
  margin: 0 auto;
  color: #fff;
}

.detail-box ul li {
  width: 15%;
  display: inline-block;
}

.detail-box ul {
  margin: 0 !important;
  background: #fff;
  padding: 0;
  padding: 14px 24px;
  -webkit-box-shadow: 0px 0px 20px 11px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 20px 11px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 10px;
}

/* Hide the radio buttons */
.buy {
  display: none;
}

div#explore {
  margin-top: 55px;
}

div#exp h2 {
  width: 46%;
  padding-left: 5px;
  border-left: 7px solid #0f2480;
  font-size: 2rem !important;
  margin: 0 auto;
}

div#exp {
  text-align: center;
}

div#snhm {
  text-align: center;
}

div#snhm h2 {
  width: 30%;
  padding-left: 5px;
  border-left: 7px solid #0f2480;
  font-size: 2rem !important;
  margin: 0 auto;
}

.spnsrd_hms {
  width: 100%;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1),
    0px 1px 2px rgba(30, 41, 61, 0.2);
  border: 1px solid#d5d5d5;
  border-radius: 6px;
  margin: 11px 0;
}

.spnsrd_hms img {
  width: 100% !important;
  height: 175px;
  object-fit: cover;
}

.spnsrd_add p:first-child {
  margin-top: 0px !important;
  color: #0f2480;
  font-weight: 600;
}
.spnsrd_add p {
  margin-top: 0px !important;
  margin-bottom: 7px;
  color: #000;
  font-size: 14px;
  padding: 0 10px;
}

.spnsrd_add h4 {
  margin-bottom: 10px;
  margin-top: 10px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 0 10px;
}

div#fninh {
  text-align: center;
}

div#fninh h2 {
  width: 55%;
  padding-left: 5px;
  border-left: 7px solid #0f2480;
  font-size: 2rem !important;
  margin: 0 auto;
}

.sale_nw .box {
  width: 100%;
  margin: 21px 0 16px 0;
}

.sale_nw .box img {
  width: 100%;
  margin-bottom: 12px;
  height: 174px;
  object-fit: cover;
}

.sale_nw .box p {
  font-size: 11px;
}

.detail-box10 h6 {
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 16px;
}
