.img-box {
  border: 1px solid#d5d5d5;
  padding: 19px;
  border-radius: 8px;
  height: 247px;
  margin-top: 45px;
}

.prpty_prc {
  text-align: center;
}

.img-box img {
  width: 24% !important;
  margin-bottom: 8px;
}

.img-box h6 {
  margin: 0px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .lidiv label {
    width: 19%;
  }
}
