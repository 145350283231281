body {
    background-color: white;
  }
  
  .profile_body {
    padding: 32px 24px;
    background-color: #fff;
  }
  .profile_upload label {
    display: flex;
    flex-direction: column;
    border: 2px dashed #005aff;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    outline: 0;
    overflow: hidden;
    transition: background-color 0.3s ease;
    padding: 0;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #005aff;
  }
  .profile_upload label svg {
    font-size: 41px;
  }
  .profile_upload label p span {
    display: block;
    text-align: center;
  }
  .profile_upload label:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background: #005aff;
    color: #fff;
    cursor: pointer;
  }
  .profile_upload {
    display: flex;
    justify-content: center;
  }
  .prfl-pic {
    text-align: center;
    margin-top: 0;
    font-size: 17px;
  }
  .profile_image img {
    height: 100%;
    object-fit: contain;
  }
  .profile_image {
    height: 310px;
    margin: 36px auto;
    max-width: 317px;
    border-radius: 166px;
    overflow: hidden;
    background-color: #fff;
  }
  .confirm-profile {
    width: 562px !important;
    background: #fbfbfb !important;
    box-shadow: unset !important;
  }
  :focus-visible {
    outline: unset !important;
  }
  .btns-svs {
    text-align: center;
  }
  .confirm-profile p {
    text-align: center;
    color: #868686;
    font-weight: 400;
    font-family: unset;
  }
  
  .confirm-profile h2 {
    margin: 0;
    text-align: center;
    font-size: 23px;
    font-weight: 600;
  }
  .btns-svs button {
    padding: 15px 60px;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid #1976d2;
  }
  button.scnd-btn {
    color: #1976d2;
  }
  