#signin {
  height: 100%;
  background: #0f2480;
  padding: 2rem 0 11rem 0;
}

.signup {
  text-align: center;
  max-width: 540px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1),
    0px 1px 2px rgba(30, 41, 61, 0.2);
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding: 2rem 3.5rem;
  margin-top: 50px;
}

.signup h3 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #0f2480;
}

.signup p {
  font-weight: 500;
  color: #424141;
  font-size: 1rem;
}

button#sbbtn {
  background: #0f2480;
  color: #fff;
  width: 100%;
  padding: 11px 0;
  border-radius: 8px;
  margin-top: 2rem;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 2rem;
}

a.frgtlink {
  font-weight: 600;
  font-size: 1.25rem;
  color: #007bff;
  text-decoration: none;
}

.or_flx {
  margin: 2.5rem 0 1.5rem 0;
  display: flex;
  justify-content: space-between;
}

span.lft_rw,
span.right_rw {
  height: 1px;
  width: 44%;
  background: #d5d5d5;
  margin-top: 0.5rem;
}

span.or {
  width: 10%;
  font-weight: 700;
  color: #686868;
}
.scl_btn button:first-child {
  width: 100%;
  background: unset;
  border: 1px solid#d5d5d5;
  font-weight: 700;
  font-size: 18px;
  padding: 14px 0;
  border-radius: 8px;
  color: #4e4e4e;
  margin: 14px 0;
  cursor: pointer;
}

.scl_btn button:nth-child(2) {
  width: 100%;
  background: rgb(24, 119, 242);
  border: 1px solid#d5d5d5;
  font-weight: 700;
  font-size: 18px;
  padding: 14px 0;
  border-radius: 8px;
  color: #fff;
  margin: 8px 0;
  cursor: pointer;
}

button.cntn_apple {
  width: 100%;
  background: rgb(24, 24, 24);
  border: 1px solid#d5d5d5;
  font-weight: 700;
  font-size: 18px;
  padding: 14px 0;
  border-radius: 8px;
  color: #fff;
  margin: 8px 0;
  cursor: pointer;
}
.scl_btn svg {
  vertical-align: middle;
  margin-right: 10px;
}

p.nt_sgndup {
  margin: 9px 0 0 0;
}
p.nt_sgndup a {
  font-size: 18px;
  text-decoration: none;
}
.sgnup_ftr p {
  margin-top: 22px;
  border-top: 1px solid#d5d5d5;
  padding-top: 23px;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
}
