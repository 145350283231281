.footer {
  margin-top: 5rem;
  background-color: #0f2480;
  color: #ffffff;
  padding: 18px 0 3px 0;
}
ul#social-icons li {
  width: 20%;
  display: inline-block;
}

.smicons {
  font-size: 31px !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.smicons.css-i4bv87-MuiSvgIcon-root {
  width: 33px !important;
}
.tradesmenServices {
  padding-top: 10px;
  background: #3750bf;
}
h2#trds {
  font-size: 21px;
  margin: 20px 0 0 0px;
}
.specser button:hover {
  background: unset;
}
.tradesmenServices li {
  padding-left: 0px;
}
.tradesmenServices li a {
  color: #fff;
  text-decoration: underline;
  font-size: 19px;
}
.real button {
  opacity: 100%;
  padding-right: 0;
  height: 31px;
  background: unset;
  border: unset;
  box-shadow: unset;
  border-radius: 0;
  border-bottom: unset !important;
}
.Specific_Services {
  padding-top: 1px;
  background: #3750bf;
}

.specser button {
  cursor: pointer;
  height: 36px;
  margin-left: 21px;
  background: unset;
  color: #fff;
  border: unset;
  box-shadow: unset;
  margin-top: 13px;
  border-bottom: 1px solid;
  border-radius: 0px;
  min-width: 193px;
  padding: 0;
}
.tabss button {
  background: unset;
  border: unset;
  box-shadow: unset;
  display: block;
}
.specser {
  display: flex;
}
.Specific_Services li {
  padding-right: 75px;
  padding-left: 0px;
  display: inline;
}
.tabss {
  padding-top: 13px;
}
div#realesta ul {
  padding-left: 0px;
}
.Specific_Services li a {
  color: #fff;
  font-size: 19px;
  text-decoration: underline;
}
ul#social-links li a {
  text-decoration: underline;
  color: #fff;
  margin-right: 27px;
  font-size: 19px;
}

ul#social-icons svg {
  color: #fff;
}
ul#social-links li {
  width: unset;
  display: inline-block;
  padding: 0;
}

#social-links {
  text-align: center;
  padding-top: 16px;
}

.professional {
  background: #3750bf;
  padding: 24px 0 5px 0;
}
.professional h2 {
  margin: 20px 0 0 0px;
  font-size: 21px;
}

.professional ul li a {
  font-size: 19px;
  color: #fff;
}
.professional ul li {
  padding-left: 0px;
  margin-bottom: 8px;
  margin: 0 20px 0 0px;
}

.rsa ul li {
  padding-left: 0px;
}

div#footb button {
  padding-bottom: 38px;
  font-size: 19px;
  text-transform: capitalize;
  border-bottom: 1px solid#bfc5dd70;
  color: #fff;
  width: 25%;
  font-weight: 600;
  align-items: unset !important;
  -webkit-box-pack: unset !important;
  padding-left: 0px;
}
span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
  background-color: #fff !important;
}

.rsa {
  position: relative;
}
.rsa button {
  top: -53px;
  left: 0;
  padding: 0;
  position: absolute;
  background: unset;
  color: #fff;
  border: unset;
  box-shadow: unset;
}
.rsa h2 {
  font-size: 21px;
}
.rsa ul li a {
  font-size: 19px;
  color: #fff;
}

.nhm {
  position: relative;
}
.nhm button {
  top: -53px;
  left: 276px;
  position: absolute;
  background: unset;
  color: #fff;
  border: unset;
  box-shadow: unset;
}
.nhm h2 {
  font-size: 21px;
}

.nhm ul li {
  padding-left: 0px;
}
.nhm ul li a {
  font-size: 19px;
  color: #fff;
}

.poparea {
  position: relative;
}
.poparea button {
  top: -53px;
  right: 449px;
  position: absolute;
  background: unset;
  color: #fff;
  border: unset;
  box-shadow: unset;
}
.poparea h2 {
  font-size: 21px;
}

.poparea ul li {
  padding-left: 0px;
}
.poparea ul li a {
  font-size: 19px;
  color: #fff;
}

.pps {
  position: relative;
}
.pps button:hover {
  background: unset;
}
.poparea button:hover {
  background: unset;
}
.nhm button:hover {
  background: unset;
}
.rsa button:hover {
  background: unset;
}
.pps button {
  top: -53px;
  right: 164px;
  position: absolute;
  padding: 0;
  min-width: 123px;
  background: unset;
  color: #fff;
  border: unset;
  box-shadow: unset;
}

.pps h2 {
  font-size: 21px;
}

.pps ul li {
  padding-left: 0px;
}
.pps ul li a {
  font-size: 19px;
  color: #fff;
}

.copyrgt {
  margin-top: 30px;
  border-top: 1px solid#bfc5dd70;
  background-color: #ffd600;
}
.copyrgt p {
  text-align: center;
  color: #0f2480;
  font-weight: 500;
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .Specific_Services li a {
    font-size: 17px;
  }
  .Specific_Services li {
    padding-right: 23px;
  }
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.smicons.css-i4bv87-MuiSvgIcon-root {
    width: 19px !important;
  }
  ul#social-links li a {
    margin-right: 19px;
    font-size: 17px;
    margin-bottom: 9px;
    display: block;
  }
  #social-links {
    text-align: center;
    padding-top: 2px;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 575px) {
  .Specific_Services li a {
    font-size: 17px;
  }
  .Specific_Services li {
    padding-right: 23px;
  }
  #footb .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
  div#footb button {
    width: 48%;
    text-align: center;
  }
  .rsa button {
    top: -3px;
    right: 0;
    left: unset;
  }
  .nhm button {
    top: -1px;
    right: 0;
    left: unset;
  }
  .poparea button {
    top: 5px;
    right: 0;
  }
  .pps button {
    top: 7px;
    right: 0;
  }
  #footb
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    background: #ffffff36;
  }
  #footb span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    display: none;
  }
  div#footb button {
    padding-bottom: 0;
  }
  .nhm h2 {
    font-size: 14px;
    width: 64%;
  }
  .poparea h2 {
    font-size: 14px;
    width: 64%;
  }
  .pps h2 {
    font-size: 14px;
    width: 64%;
  }
  .copyrgt p {
    font-size: 15px;
  }
  .tab-scrl {
    overflow: scroll;
  }
  .grd-wdth {
    max-width: 48% !important;
    flex-basis: 48% !important;
  }
  .grd-wdth li a {
    font-size: 13px !important;
  }
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.smicons.css-i4bv87-MuiSvgIcon-root {
    width: 19px !important;
  }
  ul#social-links li a {
    margin-right: 19px;
    font-size: 17px;
    margin-bottom: 9px;
    display: block;
  }
  #social-links {
    text-align: center;
    padding-top: 2px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  #social-icons {
    text-align: center;
  }
  @media screen and (min-device-width: 320px) and (max-device-width: 575px) {
    .tradesmenServices li a {
      font-size: 13px;
    }
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 575px) {
    #proflinks li a {
      font-size: 13px;
    }
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 575px) {
    h2#trds {
      font-size: 15px !important;
      margin: 20px 0 0 0px;
    }
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 575px) {
    h2#spcserv {
      font-size: 15px !important;
      margin: 20px 0 0 0px;
    }
  }
  ul#social-icons li {
    padding-bottom: 0px;
    width: 13%;
  }

  ul#social-icons svg {
    color: #fff;
  }
  ul#social-icons svg {
    width: 26px !important;
  }

  #social-links {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 12px;
  }

  ul#social-links li a {
    margin-right: 21px;
    font-size: 13px;
  }

  .professional ul li a {
    font-size: 13px;
  }

  .rsa ul li a {
    font-size: 13px;
  }

  .professional h2 {
    font-size: 14px;
  }

  div#footb button {
    font-size: 13px;
  }

  .rsa h2 {
    font-size: 14px;
  }
}
