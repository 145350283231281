.inter {
  padding-top: 4rem;
}
.inter h1 {
  font-size: 2rem !important;
  border-left: 7px solid #0f2480;
  width: 34%;
  margin: 0px auto;
  text-align: center;
  padding-left: 5px;
}
.inter p {
  margin: 0px auto;
  text-align: center;
  margin-top: 25px;
  color: #424141;
  margin-bottom: 1rem;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: blue !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.buy,
.buyg input {
  /* Add your custom styles here */
  display: none !important;
}
.buyg {
  text-align: center;
  width: 100%;
  display: block;
}
.buy:checked + label.buyg {
  background: #0f2481;
  width: 100%;
  height: 100%;
  color: #fff;
}

label.buyg {
  margin: 0;
  padding: 8px 0;
  cursor: pointer;
  font-size: 14px;
}
.internat {
  position: relative;
  padding: 10px 24px;
  -webkit-box-shadow: 0px 0px 20px 11px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 20px 11px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 45px;
  line-height: 1;
  opacity: 100%;
  color: #0f2481 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button.slick-arrow.slick-prev {
  left: 89%;
  position: absolute;
  top: unset;
  bottom: 70px;
}

button.slick-arrow.slick-next {
  right: 2%;
  position: absolute;
  bottom: 70px;
  top: unset;
}

button#filter {
  margin-top: 17px;
  text-transform: uppercase;
  height: 45px;
  width: 100%;
}

button#search {
  margin-top: 17px;
  background-color: #0f2481;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  height: 45px;
  width: 100%;
}

input#cat {
  padding: 11.5px 14px !important;
}

img#fliterimg {
  margin-right: 5px;
}

@media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
  .inter h1 {
    width: 41% !important;
  }
  .internat {
    max-width: 96%;
  }
  .property h2 {
    width: 34% !important;
  }
  .latest h2 {
    width: 34% !important;
  }
  .homelist h2 {
    width: 59% !important;
  }
  .dreamhome h1 {
    width: 24% !important;
  }
  .new h1 {
    width: 32% !important;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .inter h1 {
    width: 53% !important;
  }
  .internat {
    max-width: 96%;
  }
  .property h2 {
    width: 44% !important;
  }
  .latest h2 {
    width: 44% !important;
  }
  .homelist h2 {
    width: 77% !important;
  }
  .dreamhome h1 {
    width: 30% !important;
  }
  .new h1 {
    width: 41% !important;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 575px) {
  .inter h1 {
    width: 85% !important;
  }
  .property {
    padding-top: 3rem !important;
  }
  .lifrom {
    margin-bottom: 35px !important;
  }
  .internat {
    max-width: 96%;
  }
  .property h2 {
    width: 74% !important;
  }
  .img-box {
    margin-top: 20px !important;
  }
  .latest h2 {
    width: 73% !important;
  }
  .lat {
    margin-top: 32px !important;
  }
  button#findbtn {
    margin-top: 31px !important;
  }
  .latest {
    padding-top: 3rem !important;
  }
  .dreamhome {
    padding-top: 3rem !important;
    padding-bottom: 2rem !important;
  }
  .new {
    padding-top: 3rem !important;
  }

  .homelist {
    padding-top: 4rem !important;
    padding-bottom: 2rem !important;
  }
  .res_sldr {
    margin: 10px 0 !important;
  }
  h6.hsb {
    top: 176px !important;
  }
  .homelist h2 {
    width: 77% !important;
  }
  .dreamhome h1 {
    width: 59% !important;
  }
  .new h1 {
    width: 81% !important;
  }
  .res_sldr1 {
    margin: 10px 0 !important;
  }
  .lidiv {
    margin-bottom: 36px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .inter h1 {
    width: 72%;
    font-size: 24px !important;
  }

  .inter p {
    margin-bottom: 4rem;
  }

  button.slick-arrow.slick-next {
    right: 3%;
  }

  button.slick-arrow.slick-prev {
    left: 77%;
  }
  .inter {
    padding-top: 2rem;
  }
}
