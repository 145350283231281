.dreamhome {
  padding-top: 6rem;
  padding-bottom: 4rem;
}
.dreamhome h1 {
  font-size: 2rem !important;
  border-left: 7px solid #0f2480;
  width: 20%;
  margin: 0px auto;
  text-align: center;
  padding-left: 5px;
}
.res_sldr1 {
  position: relative;
  width: 100%;
  border: 1px solid#d5d5d5;
  border-radius: 6px;
  margin: 22px 0;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1),
    0px 1px 2px rgba(30, 41, 61, 0.2);
}
.res_sldr1 img {
  width: 100%;
  border-radius: unset;
}
h6.hsb {
  position: absolute;
  top: 147px;
  color: #fff;
}
.hmestt img {
  width: 47%;
  vertical-align: sub;
}

.hmestt {
  margin-bottom: 7px;
  margin-right: 7px;
}
.res_sldr1 p {
  margin: 0;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .dreamhome h1 {
    width: 44%;
    font-size: 26px !important;
  }
  .dreamhome {
    padding-top: 3rem !important;
    padding-bottom: 2rem !important;
  }
  .res_sldr1 {
    margin: 10px 0 !important;
  }
}
