.otp-field input {
  width: 24px;
  font-size: 32px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin: 2px;
  border: 2px solid #0f2480;
  background: #fff;
  font-weight: bold;
  color: #000;
  outline: none;
  transition: all 0.1s;
}

.otp-field input:focus {
  border: 2px solid #a527ff;
  box-shadow: 0 0 2px 2px #a527ff6a;
}

.disabled {
  opacity: 0.5;
}

.space {
  margin-right: 1rem !important;
}

button#sbbtnv {
  background: #0f2480;
  color: #fff;
  width: 78%;
  padding: 8px 0;
  border-radius: 8px;
  margin-top: 2rem;
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 2rem;
}
