/* MuiNavbar.css */

/* Style for the AppBar */
.AppBar {
  background-color: #000 !important; /* Change the background color as needed */
  padding: 10px 10px;
}

/* Style for the Toolbar */
.Toolbar {
  display: flex;
  justify-content: space-between;
}

/* Style for the logo */
.Logo {
  width: 183px;
  font-size: 1.5rem;
  font-weight: bold;
  color: white; /* Change the text color as needed */
}

/* Style for the menu items */
.MenuItems {
  display: flex;
  align-items: center;
}

.MenuItem {
  font-size: 17px !important;
  margin-right: 20px !important;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}
a.lnks {
  cursor: pointer;
  color: white;
  text-decoration: none;
  vertical-align: sub;
}

a.lnks img {
  width: 183px;
}

.MenuItem:hover {
  color: #61dafb; /* Change the hover color as needed */
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .MenuItem {
    font-size: 16px !important;
    margin-right: 0px !important;
  }
  span#selected-item {
    width: 197px !important;
  }
  .Logo {
    width: 149px;
  }
  .Logo img {
    width: 100% !important;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 575px) {
  .MenuItem {
    font-size: 16px !important;
    margin-right: 0px !important;
  }
  span#selected-item {
    width: 93% !important;
    margin-right: 0 !important;
  }
  .Logo {
    margin: 0 auto 8px auto !important;
    width: 59%;
  }
  div#lgn-sgn .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    padding-top: 0;
  }
  .Logo img {
    width: 100% !important;
  }
  #main_head {
    padding: 0;
  }
  div#crncy {
    width: 100%;
  }
  span#selected-item::before {
    right: 16px !important;
    top: 10px !important;
  }
  div#lgn-sgnm {
    width: 100%;
  }
  div#lgn-sgn {
    justify-content: center;
    margin: 0 auto;
  }
  ul#filterrequest {
    width: 317px !important;
  }
  .logo-wdth {
    width: 100%;
  }
  .dewr {
    padding-left: 0 !important;
  }
  div#crncy .MuiGrid-root.MuiGrid-item.css-13i4rnv-MuiGrid-root {
    width: 100%;
  }
}
