div#cnt_bnr {
  padding: 0px;
  margin: 0px;
  max-width: 100%;
  position: relative;
}

img.cnt-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cnt-bnr {
  height: 400px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 45%;
  left: 0%;
  transform: translate(-50%, -50%);
  background-color: rgb(0 0 0 / 19%);
  padding: 20px;
  border-radius: 10px;
  color: white;
}

img.cnt-image {
  height: 100%;
  width: 100%;
}

.cnt-box {
  padding-bottom: 0;
  padding-top: 4rem;
  text-align: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
}

.cnt-box h1 {
  width: 21%;
  padding-left: 5px;
  border-left: 7px solid #0f2480;
  font-size: 2rem !important;
  margin: 0 auto;
  color: #fff;
}

input#outlined-basic {
  background-color: #fff;
  width: 428px;
  padding: 20px 25px;
  border-radius: 0px;
}

.cnt-box button {
  background-color: #0f2481;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  height: 64px;
  padding: 0 26px;
  margin: 0 0 0 10px;
  border-radius: 0px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
}

.one_frm {
  border: 1px solid#0f2480;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 3rem;
}
.one_frm h3 {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 18px;
}
.one_frm p {
  font-weight: 500;
}
div#stk {
  margin-top: 55px;
  margin-bottom: 55px;
}

div#explorex {
  height: 162px;
  text-align: center;
  color: #000;
  font-size: 21px;
  font-weight: 700;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1),
    0px 1px 2px rgba(30, 41, 61, 0.2);
  border: 1px solid#d5d5d5;
  border-radius: 6px;
  padding: 10px;
}
div#pop {
  margin-bottom: 45px;
}
.img-box1x svg {
  margin: 5px 0px;
  height: 36px;
  width: 36px;
}

.detail-box1 h6 {
  margin: 0px;
  text-align: center;
}

.detail-box1 p {
  margin: 5px;
  font-weight: 500;
  font-size: 16px;
}

div#promt {
  margin-top: 80px;
  margin-bottom: 60px;
}

div#promt h2 {
  width: 24%;
  padding-left: 5px;
  border-left: 7px solid #0f2480;
  font-size: 2rem !important;
  margin: 0 auto;
}

div#promt h4 {
  color: #0f2480;
  font-weight: 700;
  font-size: 16px;
  vertical-align: text-top;
  margin: 0px;
}

div#promt p {
  margin: 0px;
}
