/* src/styles/ForgotPassword.css */

h3 {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1.5rem; /* Adjust the margin as needed */
    color: #0f2480;
}

.forgot-password-outer-container {
    height: 100vh;
    background-color: #0f2480;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-password-container {
    background-color: #fff;
    text-align: center;
    max-width: 440px;
    width: 100%;
    box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1), 0px 1px 2px rgba(30, 41, 61, 0.2);
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding: 2rem 3.5rem;
}

.input-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.input-field {
    width: 100%;
    padding: 16.5px 14px;
    border: 2px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s, color 0.3s;
    font-size: 16px;
    box-sizing: border-box;
    color: black; /* Text color set to black */
}

.input-field:focus {
    border-color: #3498db;
}

.input-label {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
    background: #fff;
    padding: 0 4px;
}

.input-label.focused,
.input-field:focus + .input-label {
    top: -10px;
    left: 14px; /* Aligns the label with the border */
    font-size: 12px;
    color: #3498db;
    transform: translateY(0); /* Remove vertical centering transform */
}

.submit-button {
    width: 100%;
    padding: 16.5px 14px; /* Same padding as input field */
    background-color: #0f2480;
    color: white;
    border: none;
    border-radius: 4px; /* Same border-radius as input field */
    cursor: pointer;
    font-size: 16px; /* Same font-size as input field */
    font-weight: 700;
    margin-top: 2rem;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0d47a1;
}
/* styles/ForgotPassword.css or your main stylesheet */
