.limg img {
  width: 100%;
}

.lat {
  margin-top: 50px;
}

.detail-lbox h6 {
  margin: 0px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.detail-lbox p {
  margin: 12px 0px 0 0;
}
