span#selected-item {
  cursor: pointer;
  width: 260px;
  background: #fff;
  color: #000;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 6px;
  margin-right: 14px;
}
span#selected-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: transparent;
  text-indent: -9999px;
  border-top: 2px solid #000000b3;
  border-left: 2px solid #000000b3;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: transparent;
  right: 30px;
  transform: rotate(223deg);
  top: 9px;
}

.currency-dropdown {
  position: relative;
}

ul#filterrequest img {
  width: 27px;
  margin-right: 7px;
  height: 20px;
  border: 1px solid#0000001f;
  vertical-align: middle;
}

ul#filterrequest {
  border: 1px solid#e7e7e7;
  width: 100%;
  position: absolute;
  background: #fff;
  color: #000;
  border-radius: 6px;
  overflow: auto;
  height: 307px;
  margin-top: 5px;
  z-index: 1;
  padding: 0px;
}

ul#filterrequest li {
  cursor: pointer;
  font-weight: 700;
  display: block;
  padding: 9px 14px;
  color: #0000009c;
  font-family: inherit;
}

ul#filterrequest li:hover {
  background: #007eff0f;
  border-radius: 6px;
}

span#selected-item img {
  vertical-align: bottom;
  width: 27px;
  margin-right: 7px;
  height: 20px;
  border: 1px solid#0000001f;
}

span#selected-item {
  width: 295px;
  background: #fff;
  color: #000;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 6px;
}
