.findmore{
    margin: 0px auto;
    text-align: center;
    padding-left: 5px;
   
}

button#findbtn {
    display: inline-block;
    padding: 7px 30px;
    background-color: transparent;
    color: #3554d1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid #3554d1;
    border-radius: 25px;
    margin-top: 35px;
    /* border-radius: 50px; */
}