.homelist {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.homelist h2 {
  font-size: 2rem !important;
  border-left: 7px solid #0f2480;
  width: 49%;
  margin: 0px auto;
  text-align: center;
  padding-left: 5px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .homelist h2 {
    width: 74%;
    font-size: 22px !important;
  }
}
