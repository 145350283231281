.responsive_flx {
  position: relative;
}
.res_sldr {
  position: relative;
  width: 100%;
  border: 1px solid#d5d5d5;
  border-radius: 6px;
  margin: 22px 0;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1),
    0px 1px 2px rgba(30, 41, 61, 0.2);
}
.res_sldr h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}

div#intrntnl_dsply img {
  object-fit: cover;
}
.flx_svg {
  display: flex;
}
.res_sldr img {
  width: 100%;
  border-radius: unset;
}
.tke_out {
  padding: 14px;
  padding-top: 9px;
}
.tke_out h6 {
  margin: 0px;
  font-size: 17px;
  margin-bottom: 10px;
}
.tke_out p {
  height: 45px;
  margin: 0;
  margin-bottom: 20px;
}
.hmest img {
  width: 47%;
  vertical-align: sub;
}
.tke_out span {
  margin-left: 6px;
}
.hmest {
  margin-right: 4px;
}
.list-img img {
  height: 172px;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .res_sldr {
    margin: 10px 0 !important;
  }
}
