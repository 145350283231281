.homelist1 {
    /* padding-top: 6rem; */
    /* padding-bottom: 4rem; */
    /* text-align: center;  */
    padding: 27px;
  }
  
  .homelist1 h2 {
    font-size: 2rem !important;
    /* border-left: 7px solid #0f2480; */
    /* display: inline-block; Ensures the width affects the element */
    padding-left: 5px;
    margin: 0; /* Remove default margins */
  }
  /* .res_sldr h6 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
} */
.icons svg {
  width: 15px;
  height: 22px;
  vertical-align: middle;
}
.icons .res_sldr h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
}
.icons .hmest span {
  margin-right: 6px;
  vertical-align: middle;
  font-size: 13px;
}
/* .hmest {
  margin-right: 10px;
} */
  
  @media screen and (min-device-width: 320px) and (max-device-width: 575px) {
    .homelist h2 {
      width: auto; /* Use auto width for responsiveness */
      font-size: 22px !important;
    }

  }