.new {
  padding-top: 6rem;
  padding-bottom: 2rem;
}
.newdevh {
  margin-bottom: 3rem;
}
.new h1 {
  font-size: 2rem !important;
  border-left: 7px solid #0f2480;
  width: 26%;
  margin: 0px auto;
  text-align: center;
  padding-left: 5px;
}

.responsive_flx {
  position: relative;
}

.res_sldr2 {
  position: relative;
  width: 100%;
  border-radius: 6px;
  margin: 11px 0;
  overflow: hidden;
}

div#nw_dvlpmnt img {
  height: 171px;
  width: 100%;
  object-fit: cover;
}
.overlaydrm {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: -4px;
  left: 0;
  background-color: rgb(0 0 0 / 22%);
  overflow-x: hidden;
  transition: 0.5s;
}

div#nw_dvlpmnts {
  position: absolute;
  bottom: 0;
  z-index: 999;
}
.tke_out10 {
  padding: 16px;
}

div#nw_dvlpmnts h6 {
  margin: 7px 0px;
  color: #fff;
}
.tke_out10 h6 {
  font-size: 17px;
}
.res_sldr h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

div#nw_dvlpmnts p {
  color: #fff;
}
.tke_out10 p {
  margin: 0;
}

.css-zow5z4-MuiGrid-root > .MuiGrid-item {
  padding-top: 18px !important;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .new h1 {
    width: 61%;
    font-size: 26px !important;
  }
  .new {
    padding-bottom: 0rem;
  }
}
