.latest {
  padding-top: 5rem !important;
}
.latest h2 {
  font-size: 2rem !important;
  border-left: 7px solid #0f2480;
  width: 28%;
  margin: 0px auto;
  text-align: center;
  padding-left: 5px;
  margin-bottom: 3rem;
}

.css-480o17-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
}

@media screen and (min-device-width: 320px) and (max-device-width: 575px) {
  .latest h2 {
    width: 58%;
    font-size: 23px !important;
  }
  .latest {
    padding-top: 2rem !important;
  }
  .lat {
    margin-top: 20px !important;
  }
}
