div#signup {
  height: 100%;
  background: #0f2480;
  padding: 2rem 0 8rem 0;
}

.back_btn button:first-child {
  float: left;
  width: 23% !important;
  padding: 6px 0px !important;
  color: #0f2480 !important;
  background: unset !important;
  border: 1px solid #0f2480;
  font-size: 17px !important;
  box-shadow: unset;
}
.back_btn button:nth-child(2) {
  float: right;
  width: 23% !important;
  padding: 6px 0px !important;

  border: 1px solid #0f2480;
  font-size: 17px !important;
}
.back_btn button:last-child {
  margin-right: 10px;
  float: right;
  padding: 6px 0px !important;
  border: 1px solid #0f2480;
  font-size: 17px !important;
  width: 19%;
  color: #0f2480;
}
.back_btn {
  margin-top: 17px;
  height: 66px;
}
.back_btn {
  height: 66px;
}
button#sbbtn2 {
  background: #0f2480;
  border: 1px solid #0f2480;
  color: #fff;
}

.quespg {
  text-align: center;
  max-width: 617px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(30, 41, 61, 0.1),
    0px 1px 2px rgba(30, 41, 61, 0.2);
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding: 2rem 3.5rem;
  margin-top: 50px;
}

.quespg p {
  font-weight: 500;
  color: #424141;
  font-size: 1rem;
}
.quespg h3 {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #0f2480;
}

.back_btn1 {
  margin-top: 17px;
  height: 66px;
}
.back_btn1 {
  height: 66px;
}
