/* drawer custom stylesheet */

#hhhhhhh span {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  line-height: 1.5;
  font-size: 22px !important;
  letter-spacing: 0.00938em;
  display: block;
}

#fileicon {
  color: #fff !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem !important;
}

a.customClass {
  text-decoration: none;
  color: black;
}

#custom-drawer-id {
  width: 277px;
}
